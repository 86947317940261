import {Route, Switch} from 'react-router-dom';
import ErrorView from "./view/ErrorView";
import {NavbarComponent} from "./components/layout/navbarComponent/NavbarComponent";
import {UsaView} from "./view/countryView/UsaView";
import {TeamView} from "./view/TeamView";
import {ContactUsView} from "./view/contactUsView/ContactUsView";
import {BlogView} from "./view/blogView/BlogView";
import {LandingView} from "./view/landingView/LandingView";
import {FaqView} from "./view/FaqView";
import {PrivacyPolicyView} from "./view/PrivacyPolicyView";
import {AuthorView} from "./view/AuthorView";
import {GalleryView} from "./view/GalleryView";
import {CanadaView} from './view/countryView/CanadaView';
import {BreadcrumbComponent} from "./components/layout/BreadcrumbComponent";
import {ButtonsList} from "./view/sample/ButtonsList";
import FooterComponent from "./components/layout/FooterComponent";
import {UkView} from "./view/countryView/UkView";
import {AustraliaView} from "./view/countryView/AustraliaView";
import {TermsConditionView} from "./view/TermsConditionView";

class ApplystartRoute {
    constructor(path, component, breadcrumb = null, renderLayout = true) {
        this.path = path;
        this.component = component;
        this.renderLayout = renderLayout;
        this.breadcrumb = breadcrumb;
    }
}

const siteRoutes = [
    new ApplystartRoute('/', LandingView),
    new ApplystartRoute('/usa/:subPath?', UsaView),
    new ApplystartRoute('/canada/:subPath?', CanadaView),
    new ApplystartRoute('/uk/:subPath?', UkView),
    new ApplystartRoute('/australia/:subPath?', AustraliaView),
    new ApplystartRoute('/blogs', BlogView, {path: [{title: "Blogs"}]}),
    new ApplystartRoute('/blogs/:blogId', BlogView, {title: "", path: [{title: "Blogs", link: "/blogs"}]}),
    new ApplystartRoute('/authors/:id', AuthorView, {title: "", path: [{title: "Blogs", link: "/blogs"}]}),
    new ApplystartRoute('/team', TeamView, {title: "Applystart Team", path: [{title: "Team"}]}),
    new ApplystartRoute('/faq', FaqView, {title: "Frequently Asked Questions", path: [{title: "FAQ"}]}),
    new ApplystartRoute('/gallery', GalleryView, {title: "Applystart Gallery", path: [{title: "Gallery"}]}),
    new ApplystartRoute('/privacy-policy', PrivacyPolicyView, {path: [{title: "Privacy Policy"}]}),
    new ApplystartRoute('/terms-conditions', TermsConditionView, {path: [{title: "Terms and Conditions"}]}),
    new ApplystartRoute('/contact-us', ContactUsView, {title: "Get in Touch", path: [{title: "Contact Us"}]}),
    new ApplystartRoute('/buttons', ButtonsList),
    new ApplystartRoute('*', ErrorView, null, false),
];

const menu_data = [
    {
        title: 'HOME',
        link: '/',
        mega_menu: false
    },
    {
        title: 'USA',
        link: '/usa',
        mega_menu: false,
        submenus: [
            {title: 'Study in the USA', link: '/usa'},
            {title: 'Application Checklist for USA', link: '/usa/#documentList'},
            // { title: 'Find Programs in USA', link: 'https://app.applystart.com/programs', external:true },
            {
                title: 'Request a Program',
                link: 'https://docs.google.com/forms/d/e/1FAIpQLSf6ANttUF1ZnDInLTrH9AGTCoR4jAu_24YHSHLlP7KdgOZL6Q/viewform',
                external: true
            },
            {
                title: 'Counselling for the USA',
                link: 'https://calendly.com/applystart-advisingteam/new-students-advising-session-usa',
                external: true
            },
        ]
    },
    {
        title: 'CANADA',
        link: '/canada',
        mega_menu: false,
        submenus: [
            {title: 'Study in Canada', link: '/canada'},
            {title: 'Application Checklist for Canada', link: '/canada/#documentList'},
            // { title: 'Find Programs in Canada', link: 'https://app.applystart.com/programs', external:true },
            {
                title: 'Request a Program',
                link: 'https://docs.google.com/forms/d/e/1FAIpQLSf6ANttUF1ZnDInLTrH9AGTCoR4jAu_24YHSHLlP7KdgOZL6Q/viewform',
                external: true
            },
            {
                title: 'Counselling for Canada',
                link: 'https://calendly.com/applystart-advisingteam/new-students-advising-session-with-applystart',
                external: true
            },
        ]
    },
    {
        title: 'UK',
        link: '/uk',
        mega_menu: false
    },
    {
        title: 'AUSTRALIA',
        link: '/australia',
        mega_menu: false
    },
    {
        title: 'BLOGS',
        link: '/blogs',
        mega_menu: false
    },
];

export const Routes = () => {

    return (
        <Switch>
            {
                siteRoutes.map((item) =>
                    <Route exact path={item.path}>
                        {item.renderLayout && <NavbarComponent menu_data={menu_data}/>}
                        {item.breadcrumb &&
                            <BreadcrumbComponent title={item.breadcrumb.title} path={item.breadcrumb.path}/>
                        }
                        <div className={"min-vh-60"}>
                            <item.component/>
                        </div>
                        {item.renderLayout && <FooterComponent style_2={'footer-dark bg-image footer-style-2'}/>}
                    </Route>
                )
            }
            <Route path={"*"} component={ErrorView}/>
        </Switch>
    )
}
