import React from "react";
import HeroSection from "./HeroSection";
import {Helmet} from "react-helmet";
import ApplystartButtonComponent from '../../components/ApplystartButtonComponent';
import ContactUsFormSection from "../contactUsView/ContactUsFormSection";
import sal from "sal.js";
import { useEffect } from "react";


export const AustraliaView = () => {

    useEffect(() => {
        sal();
    }, []);

    // HERO BANNER
    const backgroundImage = require("../../assets/images/views/australiaView/australiaBannerB.jpg");
    const flag = require("../../assets/images/views/australiaView/australiaBannerA.png");


    return (

        <>
            <Helmet>
                <title>{"Study in Australia: Apply to your favorite Australian University | Applystart"}</title>
                <meta name={"description"}
                      content={"Explore the benefits of studying in the USA, find study loans, scholarships, and expert consultants to guide you. Start your journey to academic excellence today."}/>
                <meta property={"og:title"} content={"Study in the USA: Apply with Applystart"}/>
                <meta property={"og:description"}
                      content={"Explore the benefits of studying in the USA, find study loans, scholarships, and expert consultants to guide you. Start your journey to academic excellence today."}/>
            </Helmet>

            <HeroSection
                backgroundImage={backgroundImage}
                flag={flag}
                heading={"Study in Australia"}
                headingStyle={"bannerWhiteRed"}
                subHeading={"Craft Your Future with Applystart "}
                caption={"Australian education blends world-class learning with a welcoming culture. From dynamic cities to awe-inspiring landscapes, it offers a perfect mix of academic rigor and life-changing adventures that shape tomorrow's global innovators."}
                floaties={["014", "003b", "099"]}
                hideBtn={true}
            />

            <div className="container mt-5 pt-5 mb-5 pb-5">
                <h2>All about studying in Australia will be added here soon</h2>
                <div className="row">
                    <div className="col-md-6 mb-5 pb-5">
                        <p>In the meantime, here are some useful resources:</p>
                        <h3>Personal Counselling</h3>
                        <p>Book a counselling session with Applystart to get started with your application journey in Australia:</p>
                        <ApplystartButtonComponent text="Book a counselling session" link="https://calendly.com/applystart-advisingteam/follow-up-advising-session-with-applystart-1" external={true} theme="primary"/>
                        <br/><br/><hr style={{borderTop: "0.5px solid var(--color-secondary)", opacity: "0.3", marginBottom:"40px"}}/>
                        <h3>Request Programs</h3>
                        <p>Request program recommendations to get a personalized list of programs according to your preferences:</p>
                        <ApplystartButtonComponent text="Program Request Form" link="https://docs.google.com/forms/d/e/1FAIpQLSf6ANttUF1ZnDInLTrH9AGTCoR4jAu_24YHSHLlP7KdgOZL6Q/viewform " external={true} theme="primary"/>
                    </div>
                    <div className="col-md-6">
                        <div className="contact-form form-style-2">
                            <div className="section-title">
                                <h4 className="title">Get In Touch</h4>
                                <p>Let us know if you have any questions about studying in Australia.</p>
                            </div>
                            <ContactUsFormSection />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}