import React, {useEffect} from "react";
import AdBannerComponent from "../../components/AdBannerComponent";
import {LandingHero} from "./HeroSection";
import { TypingTextSection } from "./TypingTextSection";
import { ApplicationStepsSection } from "./ApplicationStepsSection";
import FlyingBannerSection from "./FlyingBannerSection";
import SubHeroSection from "./SubHeroSection";
import CountryCardSection from "./CountryCardSection";
import CareerSection from "./CareerSection";
import CTASection from "./CTASection";
import WisdomCornerSection from "./WisdomCornerSection";
import sal from "sal.js";
import {ScorecardSection} from "./ScorecardSection";
import {TeamSection} from "./TeamSection";
import ApplystartButtonComponent from "../../components/ApplystartButtonComponent";


export const LandingView = () => {

    useEffect(() => {
        sal();
    }, []);

    // WE MAKE UNIVERSITY APPLICATIONS EASIER FOR YOU
    const subHeroContent = ()=>{
        const list = [
            {
                title: "Weekly Counselling Sessions",
                subtitle: "Guidance & Insights From Current International Students"
            },
            {
                title: "Application Processing",
                subtitle: "Submit Applications In A Few Clicks"
            },
            {
                title: "Visa Processing",
                subtitle: "Expert Advice By Immigration Lawyers"
            },
        ]
        return (
            <div className="HeroSubItems1">
                {
                    list.map((item, index)=>
                        <div className="textBox landing-subsection" data-sal-delay={150+150*index} data-sal="slide-left" data-sal-duration="800">
                            <img src={require("../../assets/svgs/iconCheckmarkCircledRed.svg").default} className="usa_icon01" alt="Checklist Icon"/>
                            <div>
                                <h2>{item.title}</h2>
                                <p>{item.subtitle}</p>
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }

    const socials = [
        { link: 'https://www.facebook.com/Applystart.Inc', target: '_blank', icon: 'bi bi-facebook', name:"facebook"},
        { link: 'https://www.instagram.com/applystart/', target: '_blank', icon: 'bi bi-instagram', name:"instagram" },
        { link: 'https://www.tiktok.com/@applystart.com', target: '_blank', icon: 'bi bi-tiktok', name:"tiktok"},
    ];


    return (
        <>

            <LandingHero socials={socials}/>
            <ScorecardSection/>
            <TypingTextSection />
            <ApplicationStepsSection />
            <FlyingBannerSection/>
            <SubHeroSection
                bgImg={require("../../assets/images/views/landingView/sectionImg02.png")}
                img={require("../../assets/images/views/landingView/sectionImg01.png")}
                title={"We Make University Applications Easier Than Ever For You"}
                className={"bannerBlackWhite h2"}
                content={subHeroContent}
                floaties={["064","108","104"]}
            />
            <br/><br/><br/>
            <CTASection/>
            <br/><br/><br/>
            <CountryCardSection/>
            <CareerSection/>
            <TeamSection/>
            <hr style={{borderTop: "1px dashed red"}}/><br/>
            <WisdomCornerSection/>
            <AdBannerComponent
            callText={
                <>
                <h2>Explore Your Dream Destination</h2>
                <p>With thousands of universities across an ever-expanding lineup of countries, Applystart has got you covered. <b>Find valuable information about the country of your dreams:</b></p>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 d-flex align-items-center justify-content-center">
                            <ApplystartButtonComponent text={"Study in the USA"} link={"/usa"} iconLeft={"bi bi-globe-americas"} theme={"tertiary mt-2 mb-2 w-100"} external={false}/>
                        </div>
                        <div className="col-sm-6 d-flex align-items-center justify-content-center">
                            <ApplystartButtonComponent text={"Study in Canada"} link={"/canada"} iconLeft={"bi bi-globe-americas"} theme={"tertiary mt-2 mb-2 w-100"} external={false}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 d-flex align-items-center justify-content-center">
                            <ApplystartButtonComponent text={"Study in the UK"} iconLeft={"bi bi-globe-europe-africa"} theme={"tertiary mt-2 mb-2 w-100"} external={false}/>
                        </div>
                        <div className="col-sm-6 d-flex align-items-center justify-content-center">
                            <ApplystartButtonComponent text={"Study in Australia"} iconLeft={"bi bi-globe-asia-australia"} theme={"tertiary mt-2 mb-2 w-100"} external={false}/>
                        </div>
                    </div>
                </div>
            </>
            } 
            // buttonText={"Frequently asked questions"}
            // link={"/faq"} 
            // external={false}
            />
        </>
    )
}