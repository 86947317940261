import React, {useState} from 'react';
import "../../assets/styles/_curtain.scss";

export const CurtainComponent = () => {
    const [isRaised, setIsRaised] = useState(false);

    return (
        !isRaised &&
        <>
            {/*Cloud Container*/}
            <div className="cloudContainer">
                <img className="c1" src={require("../../assets/images/backgrounds/cloudsRed.svg").default}/>
                <img className="c2" src={require("../../assets/images/backgrounds/cloudsRed3.svg").default}/>
                <img className="c3" src={require("../../assets/images/backgrounds/cloudsRed2.svg").default}/>
            </div>

            {/*Logo Loading*/}
            <div onAnimationEnd={() => setTimeout(() => setIsRaised(true), 900)}
                 className={"curtain min-vw-100 min-vh-100 h-100 w-100 bg-light z-10 " + (isRaised ? "raised" : "")}>
                <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                    <title>Applystart A Logo</title>

                    <defs>
                        <clipPath id="clip-reveal">
                            <rect x="0" y="0" width="100%" height="100%"/>
                        </clipPath>
                    </defs>

                    <path className="stroke-path"
                          d="m230.3 99.4l-53.3 118.8-24.3 53.3-52.5 114-41.2-90.5c-8.1-13-24.3-16.2-37.2-8.9-10.5 6.5-15.4 19.4-11.3 31.5l66.2 143.9c4.1 9.7 13.8 15.4 24.3 15.4 10.5 0 20.2-6.5 24.2-15.4l67.1-147.9 19.4-42.9 24.3-53.3 30.7-66.3-29.9-65.5z"/>
                    <path className="stroke-path"
                          d="m487.4 439.7l-181.1-401c-6.5-12.9-22.6-19.4-35.6-12.9-5.6 2.4-10.5 7.3-12.9 12.9l-4.8 11.4 29.9 64.6 139 307.2-32.3 0.8-20.2-45.3-65.5-144.7c0-0.8-0.8-0.8-0.8-0.8l-7.3-14.5-19.4 43.6-0.8 1.6-9.7 19.4 80.8 178.7c4.1 9.7 13.8 16.2 24.3 16.2h92.1c8.9 0 17.8-4.9 22.7-13 4.8-6.4 5.6-16.1 1.6-24.2z"/>

                    <path className="fill-path"
                          d="m230.3 99.4l-53.3 118.8-24.3 53.3-52.5 114-41.2-90.5c-8.1-13-24.3-16.2-37.2-8.9-10.5 6.5-15.4 19.4-11.3 31.5l66.2 143.9c4.1 9.7 13.8 15.4 24.3 15.4 10.5 0 20.2-6.5 24.2-15.4l67.1-147.9 19.4-42.9 24.3-53.3 30.7-66.3-29.9-65.5z"/>
                    <path className="fill-path"
                          d="m487.4 439.7l-181.1-401c-6.5-12.9-22.6-19.4-35.6-12.9-5.6 2.4-10.5 7.3-12.9 12.9l-4.8 11.4 29.9 64.6 139 307.2-32.3 0.8-20.2-45.3-65.5-144.7c0-0.8-0.8-0.8-0.8-0.8l-7.3-14.5-19.4 43.6-0.8 1.6-9.7 19.4 80.8 178.7c4.1 9.7 13.8 16.2 24.3 16.2h92.1c8.9 0 17.8-4.9 22.7-13 4.8-6.4 5.6-16.1 1.6-24.2z"/>
                </svg>
            </div>
        </>
    )
}