import React from "react";
import ApplystartButtonComponent from "../../components/ApplystartButtonComponent";

export const LandingHero = (props) => {
    return (
        <>
        <div className="bannerPlaceholder" style={{backgroundColor:"#542c3a"}}/>
        <div className="hero-banner hero-style-8 video-container">
            {/* <div className="hero-banner hero-style-8" style={{minHeight:"0", height:"100vh", maxHeight:"660px"}}> */}
                <video autoPlay muted loop playsInline className="background-video">
                    <source src={require("../../assets/videos/heroVideo-red.webm")} type="video/mp4" />
                    <source src={require("../../assets/videos/heroVideo-red.mp4")} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-8">
                            <div className="banner-content landing">
                                <h1 className="title bannerWhiteBlack" data-sal-delay="100" data-sal="slide-up" data-sal-duration="1000">Where Aspirations Take Flight</h1>
                                <p data-sal-delay="200" data-sal="slide-up" data-sal-duration="1000">Easier,  Cheaper and Faster International University Applications from the comfort of your home.</p>
                                <div className="banner-search" data-sal-delay="400" data-sal="slide-up" data-sal-duration="1000">
                                    {/* <div className="input-group searchbox-container">
                                    <input 
                                        type="text" 
                                        className={`form-control input1 ${inputValue.trim() !== ''?"hasInput":""}`}
                                        placeholder="(program)"
                                        value={inputValue}
                                        onChange={handleChange}
                                    />
                                        <input type="text" className="form-control input2" placeholder="(institution)" />
                                        <button className="search-btn" type="button"><i className="icon-2 banner-search-icon"></i></button>
                                    </div> */}
                                    <ApplystartButtonComponent text="Apply now" link="https://app.applystart.com/" external={true} theme="primary mb-3"/>
                                    <ApplystartButtonComponent text="Book a free counseling session" link="https://linktr.ee/applystart" external={true} theme="tertiary hollow"/>
                                    <div className="landing-hero-socials">
                                        {props.socials.map((social, i) => (
                                            <a href={social.link} target={social.target ? social.target : ''}>
                                            <i className={social.icon}></i>
                                            </a>
                                    ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            {/* <div className="banner-thumbnail">
                                <div className="thumbnail" data-sal-delay="500" data-sal="slide-left" data-sal-duration="1000">
                                    <img src="/assets/images/shapes/home8-vactor-bg.webp" alt="vactor Image" />
                                </div>

                                <div className="instructor-info" data-sal-delay="600" data-sal="slide-up" data-sal-duration="1000">
                                    <div className="inner">
                                        <h5 className="title">Instrunctor</h5>
                                        <div className="media">
                                            <div className="thumb">
                                                <img src="/assets/images/shapes/home8-vactor-bg.webp" alt="vactor Image" />
                                            </div>
                                            <div className="content">
                                                <span>200+</span> Instactors
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            {/* </div> */}
        </div>
        </>
    )
}